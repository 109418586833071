import React, { Component } from 'react';

export class EmailForm extends Component {
  constructor() {
    super();
    this.state = { message: '' };
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    var http = new XMLHttpRequest();
    var url = 'https://midnighttravelclubsignup.azurewebsites.net/api/SignUp?code=EtjHxs4URXlu0xfoWIWRDiOSqwJZda4FN746DStG9zf99tUJT7KMdQ==';
    var params = '{"name":"'+ e.currentTarget[0].value +'"}'
    this.setState({ message: " thank you!" });
    http.open('POST', url, true);

    //Send the proper header information along with the request
    http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');

    http.send(params);
    setTimeout(() => {
      this.setState({ message: '' });
    }, 3500);
  }

  render() {
    const { message } = this.state;
    return (
      <form id="signup-form" onSubmit={this.onSubmit} method="post" action="#">
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email Address"
        />
        <input type="submit" value="Sign Up" />
        <span className={`${message ? 'visible success' : ''} message`}>
          {message}
        </span>
      </form>
    );
  }
}

export default EmailForm;
